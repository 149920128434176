import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/service/common.service';
import { VoucherModalComponent } from '../voucher-modal/voucher-modal.component';
import { Router } from '@angular/router';
import CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { ESignatureComponent } from '../esignature/esignature.component';
import { config } from 'process';
declare var appConfig;
@Component({
  selector: 'app-order-compelete-modal',
  templateUrl: './order-compelete-modal.component.html',
  styleUrls: ['./order-compelete-modal.component.css']
})
export class OrderCompeleteModalComponent implements OnInit {
  TempOrderDetailData: any;
  deviceinfo: any;
  CustomerInfo: any;
  TestGroupingList: any;
  SelectedDiagnoseForChild: any;
  LanguageData: any;
  userInfo: any;
  @Output() emitService = new EventEmitter();
  DigitalSigQrcode: string;
  public IsContractSigned = false;
  img: any;
  VoucherPdf: string;
  custdetails: any;

  constructor(private _service: CommonService, private spinner: NgxSpinnerService, private modalService: NgbModal,
    public activeModal: NgbActiveModal, private router: Router,
    private toster: ToastrService) { }

  ngOnInit() {
    debugger
    this.userInfo = JSON.parse(sessionStorage.getItem('StoreInfo'));
    this.VoucherPdf = appConfig.SFTPVoucherPath + this.TempOrderDetailData.OrderDetailID + '.pdf';
    console.log(this.TempOrderDetailData)
    var body = document.body;
    var languageID = sessionStorage.getItem('LanguageID');
    if (languageID && languageID !== "") {
      if (languageID == "5") {
        body.classList.add('Polish');
      }
      else {
        body.classList.remove('Polish');
      }

    }




  }
  printWrapper() {
    debugger
    console.log(this.deviceinfo);
    var TestName ;
    var GroupTestsList = [];
    let orderData = this.deviceinfo;
    orderData['CosmaticCondition'] = appConfig.cosmaticTest.filter(x => x.id == this.deviceinfo.AfterStoreInspectionTestResult
      .filter(x => x.TestTypeID == 3 && x.IsParentPass == false)[0].GroupID)[0].value
      // #region 14-03-2024
    if(orderData.IsWebManualOrder){
      var _lst = this.deviceinfo.BeforeStoreInspectionTestResult.filter(x => this.deviceinfo.FailTestId.split(',').includes(x.GroupID.toString()) && x.TestTypeID != 3)
     // .filter(x => x.TestTypeID == 3 && x.IsParentPass == false)[0].GroupID)
      if(_lst.length == 0)
      {orderData["TestName"] = 'N/A';}
      else{
      for (let index = 0; index < _lst.length; index++) {
        TestName += _lst[index].GroupName+ ', ';
        orderData["TestName"] = TestName.replace(/undefined/g, '').replace(/,\s*$/, '') ;
      }
    }
    }
    else{
      //var _lst = JSON.parse(sessionStorage.getItem("MobileTestGroupingList"));
     var  _lst = this.deviceinfo.BeforeStoreInspectionTestResult.filter(x => this.deviceinfo.FailTestId.split(',').includes(x.GroupID.toString()) && x.TestTypeID != 3)
       // .filter(x => x.TestTypeID == 3 && x.IsParentPass == false)[0].GroupID)
       if(_lst.length == 0)
       {orderData["TestName"] = 'N/A';}
       else{
        for(var i = 0; i < _lst.length; i++)  {         
          GroupTestsList = _lst[i].GroupTestsList;
          if(GroupTestsList.length > 0){
           for(var j = 0; j < GroupTestsList.length; j++){
            if(GroupTestsList[j].TestStatus == 0 || GroupTestsList[j].TestStatus == -1){
            TestName += GroupTestsList[j].TestName+ ', ';
             orderData["TestName"] = TestName.replace(/undefined/g, '').replace(/,\s*$/, '') ;
            }
          }
          }
      } 
    }  
    }
    // #endregion 14-03-2024
    sessionStorage.setItem("WrapperData", JSON.stringify(this.deviceinfo));
    let url = '/print-order-wrapper/' + this.TempOrderDetailData.OrderDetailID;
    window.open(url, '_blank');
  }


  viewDetails() {
    debugger
    this.getDeviceConditionsImage(this.deviceinfo);
    this.GetCelldeTestgroupings(this.deviceinfo.DeviceCategoryID, (res) => {
      if (res != null) {
        this.deviceinfo.QuotedPrice = Number(this.deviceinfo.QuotedPrice);
        // this.ActualQuotedPrice = this.deviceinfo.QuotedPrice;
        // console.log('test', this.TestGroupingList);
        this.CustomerInfo.OrderData = this.deviceinfo;
        this.spinner.show();
        const reBody = {
          McheckInformationID: this.deviceinfo.McheckInformationID
        };

        this._service.Add(reBody, 'api/get-mcheck-info-diagnostic-result').then(result => {

          console.log(result)
          if (result.IsSuccess) {
            this.TestGroupingList.forEach(list => {
              if (list.GroupTestsList) {
                let TotalChildTest = 0;
                let TotalPassTest = 0;
                TotalChildTest = list.GroupTestsList.length;
                list.GroupTestsList.forEach(childtest => {
                  result.Data.forEach(res => {
                    if (childtest.TestID == res.DiagnosticID) {
                      childtest.TestStatus = Number(res.DeviceScanResult)
                      console.log(childtest.TestName + " " + childtest.TestStatus);
                      if (Number(res.DeviceScanResult) == 1 && (Number(res.DeviceScanResult) !== -2)) {
                        TotalPassTest += 1
                      }
                    }
                  });
                  const counttest = list.GroupTestsList.filter(x => x.TestStatus !== -2);
                  TotalChildTest = counttest.length
                  if (TotalChildTest == TotalPassTest && TotalChildTest > 0) {
                    list.IsParentPass = true
                  } else {
                    list.IsParentPass = false
                  }
                });
                if (TotalChildTest == 0) {
                  list.IsParentPass = true
                }

              }
            });
            console.log(11, this.TestGroupingList);
          }
        })
          .catch(error => {
            this.spinner.hide();
            console.log(error);
          });

        ;
        // this.CustomerInfo.OrderData.DeductionAmount = Math.ceil((+this.deviceinfo.ActualPrice * this.deviceinfo.PercentageDeduction) / 100)
        this.CustomerInfo.OrderData.DeductionAmount = this.deviceinfo.ActualPrice - this.deviceinfo.ActualQuotedPrice;
        this.deviceinfo.PercentageDeduction =parseInt(this.deviceinfo.PercentageDeduction);
        this.CustomerInfo.OrderData.DeviceFullPrice = this.deviceinfo.ActualPrice;
        this.CustomerInfo.OrderData.PercentageDeducation = this.deviceinfo.PercentageDeduction;
        this.CustomerInfo.showcancelbutton = 'child';
        this.CustomerInfo.showsigncontractbutton = 'child';
        this.CustomerInfo.AssetsID = this.deviceinfo.AssetsID;
        this.CustomerInfo.Assets = this.deviceinfo.Assets;
        if (this.CustomerInfo.OrderData.SerialNumber != null && this.CustomerInfo.OrderData.SerialNumber != undefined && this.CustomerInfo.OrderData.SerialNumber != 'N/A' && this.CustomerInfo.OrderData.SerialNumber.length > 20) {
          this.CustomerInfo.OrderData.TempSerialNumber = this.CustomerInfo.OrderData.SerialNumber.substring(0, 10) + ' ...';
        }
        else {
          this.CustomerInfo.OrderData.TempSerialNumber = this.CustomerInfo.OrderData.SerialNumber;
        }
        this.CustomerInfo.OrderData.IsContractSigned = this.TempOrderDetailData.IsContractSigned;
        debugger
        this.SelectedDiagnoseForChild = this.CustomerInfo;
        this.emitService.emit(this.SelectedDiagnoseForChild);
        this.spinner.hide();
      }
      else {
        console.log("Null Response While Getting Tests");
      }
      this.spinner.hide();
    });


  }

  getDeviceConditionsImage(details) {

    const reBody = {
      UDI: 'BE1ED90E82974D3',
      //UDI: details.UDI,
    };
    this._service.Add(reBody, 'api/get-capturing-condition-images').then(result => {

      if (result && result.IsSuccess && result.Data.ImagesList && result.Data.ImagesList.length > 0) {
        this.SelectedDiagnoseForChild.DeviceConditionImages = result.Data.ImagesList;
      }
    })
      .catch(error => {
        this.spinner.hide();
        console.log(error);
      });
  }
  GetCelldeTestgroupings(DeviceCategoryID, callback) {

    this.LanguageData = JSON.parse(sessionStorage.getItem('LanguageID'));
    this.spinner.show();
    const reBody = {
      CacheManagerID: this.userInfo.CacheManagerID,
      EnterprisePartnerID: this.userInfo.EnterprisePartnerID,
      SubscriberProductID: appConfig.SubscriberProductID,//this.userInfo.SubscriberProductID,
      LanguageID: this.LanguageData,
    };

    this._service.Add(reBody, 'api/get-cellde-test-grouping').then(result => {

      if (result.IsSuccess) {
        let mchecktestjson = null;
        if (result.Data.mCheckTestGroup != "") {
          mchecktestjson = JSON.parse(result.Data.mCheckTestGroup)
        }
        let childgroup = [];

        if (mchecktestjson !== null && mchecktestjson.IsSuccess) {
          childgroup = mchecktestjson.Data.Table;
        }

        let testIdsWithStatus = [];
        const _Result = [];

        let ParentTest = Array.from(new Set(result.Data.CelldeTestGroup.CelldeTestGroup.Table
          .filter(x => x.DeviceCategoryID == DeviceCategoryID).map((item: any) => item.ParentTest)))

        ParentTest.forEach(paren => {
          testIdsWithStatus = [];
          let Deduction = '';
          let GroupID = null
          let TestImageID = null
          let TestTypeID = null
          result.Data.CelldeTestGroup.CelldeTestGroup.Table.forEach(element => {
            if (paren == element.ParentTest) {
              GroupID = element.ParentID
              TestImageID = element.TestImageID
              Deduction = element.Deduction
              TestTypeID = element.TestTypeID
              childgroup.forEach(child => {
                if (element.TestID == child.TestID) {
                  testIdsWithStatus.push({
                    TestID: element.TestID,
                    TestName: child.TestName,
                    TestStatus: null

                  })
                }
              });


            }
          });
          let elementdata = {
            TestTypeID: TestTypeID,
            GroupID: GroupID,//'group_' + paren,
            TestImageID: TestImageID,
            GroupName: paren,
            IsParentPass: null,
            GroupDeduction: Deduction,
            GroupTestsList: testIdsWithStatus
          };
          _Result.push(elementdata)
          // Deduction=null;

        });
        console.log(_Result)
        this.TestGroupingList = _Result
        this.CustomerInfo = {
          Result: _Result
        }
        this.SelectedDiagnoseForChild = this.CustomerInfo;
        sessionStorage.setItem("WrapperData", JSON.stringify(this.SelectedDiagnoseForChild));
      } else {
        console.log('Error:', result.ErrorMessage);
      }
      // setTimeout(() => {
      //   this.spinner.hide();
      // }, 500);

      //this.GetCachedData();

      callback(true);

    })
      .catch(error => {
        this.spinner.hide();
        console.log(error);
        callback(null);
      });
  }
  PrintVoucher() {
    // window.open(this.VoucherPdf, '_blank');
    sessionStorage.setItem('fileName', this.VoucherPdf);
    window.open('print-file', '_blank');
  }
  fnOpenPendingPage() {
    this.activeModal.dismiss();
    document.body.classList.remove('Polish');
    document.body.classList.remove('popup-open');
    this.router.navigate(['/store-dashboard']);
  }
  printcontract(PrintContractCopy) {
    debugger
    this.custdetails = JSON.parse(sessionStorage.getItem('cusdetails'));
    this.deviceinfo.PostCode = this.custdetails.PostCode;

    if(this.custdetails.IdentityType=="2" || this.custdetails.IdentityType=="3"){
      // check incase identity type is already assigned
    var IdentityType=appConfig.IdentityTypes.find(x => x.id == this.custdetails.IdentityType);
    this.deviceinfo.IdentityType=IdentityType.value;
    }

    sessionStorage.setItem('PrintContractCopy', PrintContractCopy)
    this.deviceinfo.OrderDetailID = this.TempOrderDetailData.OrderDetailID;
    sessionStorage.setItem("WrapperData", JSON.stringify(this.deviceinfo));
    let url = '/print-contract/' + this.TempOrderDetailData.OrderDetailID;
    window.open(url, '_blank');
  }
  opensignatureModal(item) {
    debugger
    this.img = null;
    let storeInfo = JSON.parse(sessionStorage.getItem('StoreInfo'));
    var ExpiryTime = new Date();
    ExpiryTime.setMinutes(ExpiryTime.getMinutes() + appConfig.ContractPageExpiryTime);
    var language = sessionStorage.getItem('language');

    if(item.IdentityType=="2" || item.IdentityType=="3"){
       // check incase identity type is already assigned
      var IdentityType=appConfig.IdentityTypes.find(x => x.id == item.IdentityType);
      item.IdentityType=IdentityType.value;
    }
    var dateObj = new Date();
    var currentDate = dateObj.getDate() + '/' + (dateObj.getMonth() + 1) + '/' + dateObj.getFullYear();
    let obj = {
      FirstName: item.FirstName,
      CustomerLastName: item.LastName,
      CompanyName: item.CompanyName,
      IdentityType: item.IdentityType,
      TempOrderID: item.ID,
      Make: item.Make,
      Model: item.Model,
      Capacity: item.Capacity,
      SerialNumber: item.SerialNumber,
      IMEI: item.IMEI,
      Street: item.Street,
      BuildingNo: item.BuildingNo,
      ApartmentNo: item.ApartmentNo,
      PostCode: item.PostCode,
      IdentificationNo: item.IdentificationNo,
      IDNo: item.IDNo,
      PESEL: item.PESEL,
      City: item.City,
      QuotedPrice: item.QuotedPrice,
      ContactName: item.FirstName + ''+ storeInfo.LastName,
      EnterprisePartner: storeInfo.EnterprisePartner,
      EnterprisePartnerID: storeInfo.EnterprisePartnerID,
      CurrencySymbol: storeInfo.CurrencySymbol,
      Store: storeInfo.Store,
      StoreID: storeInfo.StoreID,
      OrderDetailID: this.TempOrderDetailData.OrderDetailID,
      ExpiryTime: ExpiryTime,
      Name: storeInfo.Name,
      LastName: storeInfo.LastName,
      StoreCity: storeInfo.City,
      LanguageCode: language,
      IsCommercialSeller: item.IsCommercialSeller,
      NIP: item.NIP,
      OrderCreatedOn: currentDate
    }
    //=JSON.stringify(obj);
    let strg = CryptoJS.AES.encrypt(JSON.stringify(obj), '123').toString();
    let currentUrl = location.origin;
    currentUrl = currentUrl + '/print-contract/'
    this.DigitalSigQrcode = currentUrl + 'data?data=' + strg;
    console.log('Qrcode Url:- ' + this.DigitalSigQrcode);
    const modalRef = this.modalService.open(ESignatureComponent, { size: 'lg' });
    modalRef.componentInstance.DigitalSigQrcode = this.DigitalSigQrcode;
    modalRef.componentInstance.OrderData = obj;
    modalRef.componentInstance.emitService.subscribe((value) => {
      this.TempOrderDetailData.IsContractSigned = value;
    })
  }
  printSigncontract() {

    sessionStorage.setItem('fileName', appConfig.SignContractPath + 'SaleAgreement_' + this.TempOrderDetailData.OrderDetailID + '.pdf');
    window.open('print-file', '_blank');
  }
}