import { Component, ElementRef, EventEmitter, Input, NgZone, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { SocketService } from '../../service/socket/socket.service';
import { CustomerInfoService } from '../../Service/customerinfoService';
import { element, promise } from 'protractor';
import { forEach } from '@angular/router/src/utils/collection';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TranslatePipe } from '../../manual/i18n/translate.pipe';
import { TranslateService } from '../../manual/i18n/translate.service';
import { Http } from '@angular/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TruncatePipe } from 'src/app/common/limitto.pipe';
import { ImageViewerComponent } from 'src/app/model-popup/image-viewer/image-viewer.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import CryptoJS from 'crypto-js';
import { ESignatureComponent } from 'src/app/model-popup/esignature/esignature.component';
import { store } from '@angular/core/src/render3';
declare var appConfig;
@Component({
  selector: 'app-dashboard-child-root',
  templateUrl: './dashboardchild.component.html',
  styleUrls: ['./dashboardchild.component.css']
  // templateUrl: `./devicechild.component.html?v=${new Date().getTime()}`,
  // styleUrls: [`./devicechild.component.css?v=${new Date().getTime()}`]

})
export class DashboardChildComponent {
  public disableControl = true;
  public OrderCreationOn;
  interval: any;
  CustomerInfo: any;
  public groupList: any;
  public testList = [];
  public groupresults = [];
  public galleries = [];
  public prices = Array(100, 200, 300, 400, 500, 600, 700, 800, 1000);
  public devicePrice: number;
  public faultGroup: any;
  public myForm: FormGroup;
  public responseHtml: any;
  public translatePipe: any;
  public Comments: any;
  contractmail = false;
  public isCancelModal: boolean = false;
  public testgroupdetails = [];

  public Cache_PhoneModelList: any;
  public Cache_PricingList: any;
  public Cache_TestList: any;
  public StoreInfo: any;

  public TestListWithGroupDeduction;

  public PaymentTypeTranslation: any;

  @Input("SelectedDiagnose") SelectedDiagnose: any;

  @Input("CompleteCachedData") CompleteCachedData: any;
  @ViewChild('diagnoseModalID') diagnoseModalID: ElementRef
  @Output() RefreshStoreList = new EventEmitter<boolean>();

  ShowModal: any;
  diplayModal: string;
  diplayModalClass: any;
  modalStyle: {};
  displayStyleModal: boolean;
  img: any;
  DigitalSigQrcode: string;
  IsContractSigned: any;
  ModalStyle: any;
  display: string;
VoucherPdf: any;  constructor(
    private _service: CommonService,
    private socketService: SocketService,
    private ngZone: NgZone,
    private fb: FormBuilder,
    private router: Router,
    private http: Http,
    private spinner: NgxSpinnerService,
    private toster: ToastrService,
    private modalService: NgbModal,
    private translate: TranslateService

  ) {
    // this.http.get("assets/mailtemplate/wrapper.component.html").subscribe(res => {
    //     this.responseHtml = res;
    //   });
    debugger
    this.translatePipe = new TranslatePipe(translate);

  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnChanges() {
    this.VoucherPdf = appConfig.SFTPVoucherPath;
    debugger
    console.log('date', this.SelectedDiagnose)
    this.contractmail = false;
    //this.TestListWithGroupDeduction = [];
    this.TestListWithGroupDeduction = this.SelectedDiagnose;
    if (this.SelectedDiagnose !== undefined) {
      this.diplayModalClass = true;
      this.displayStyleModal = true;
      this.ModalStyle = {
        display: 'block',
        'z-index': 99999
      }
      this.filltestdata();
    }
  }

 
  fnClosePopUp() {
    this.SelectedDiagnose.OpenModalPopUp = false;
   this.isCancelModal = false;
    this.display='none';
    this.diplayModalClass = false;
    this.ModalStyle = {
      display: 'none',
      'z-index': 9999
    }
    this.CompleteCachedData = false;
   document.body.classList.remove('modal-open');
  }


  uniqueBy(arr, prop) {
    return arr.reduce((a, d) => {
      if (!a.includes(d[prop])) {
        a.push(d[prop]);
      }
      return a;
    }, []);
  }

  filtertestResultdata() {
    this.testgroupdetails = [];
    if (this.groupList !== undefined) {
      this.groupList.forEach(group => {

        const testlist = group.tests.split(",");
        const singletestlist = [];
        testlist.forEach(test => {

          let status = "";
          const testdata = this.testList.find(x => x.TestName === test);
          if (testdata !== undefined && testdata !== "") {
            //console.log(test , testdata.TestStatus );
            if (testdata.TestStatus.trim() === "-2") {
              testdata.TestStatus = "notsupported";
            } else if (testdata.TestStatus.trim() === "-1") {
              testdata.TestStatus = "?";
            } else if (testdata.TestStatus.trim() === "0") {
              testdata.TestStatus = "failure";
            } else if (testdata.TestStatus.trim() === "1") {
              testdata.TestStatus = "completed";
            } else {
              testdata.TestStatus = "?";
            }
            status = testdata.TestStatus;
          } else {
            status = "?";
          }
          const singletest = {
            TestName: test,
            TestStatus: status
          };
          if (singletest.TestStatus !== "notsupported") {
            singletestlist.push(singletest);
          }
        });
        this.testgroupdetails.push({
          GroupName: group.GroupName,
          GroupTestsList: singletestlist,
          GroupDeduction: group.deduction
        });
      });
    }
  }

  filltestdata() {
    if (this.SelectedDiagnose !== undefined) {

      this.testList = [];
      this.testList.push({
        TestID: 34,
        TestName: "Bluetooth",
        TestStatus: this.SelectedDiagnose.bluetooth
      });
      this.testList.push({
        TestID: 35,
        TestName: "Wifi",
        TestStatus: this.SelectedDiagnose.wifi
      });
      this.testList.push({
        TestID: 39,
        TestName: "Vibration",
        TestStatus: this.SelectedDiagnose.vibration
      });
      this.testList.push({
        TestID: 36,
        TestName: "Sim Card Removed",
        TestStatus: this.SelectedDiagnose.simcard
      });
      this.testList.push({
        TestID: 16,
        TestName: "SD Card Removed",
        TestStatus: this.SelectedDiagnose.sdcard
      });
      this.testList.push({
        TestID: 42,
        TestName: "Kill Switch Disabled",
        TestStatus: this.SelectedDiagnose.killswitch
      });
      this.testList.push({
        TestID: 49,
        TestName: "Not Rooted",
        TestStatus: this.SelectedDiagnose.rooted
      });
      this.testList.push({
        TestID: 30,
        TestName: "Call Function",
        TestStatus: this.SelectedDiagnose.call
      });
      this.testList.push({
        TestID: 18,
        TestName: "Jack",
        TestStatus: this.SelectedDiagnose.jack
      });
      this.testList.push({
        TestID: 56,
        TestName: "Volume Buttons",
        TestStatus: this.SelectedDiagnose.volume
      });
      this.testList.push({
        TestID: 45,
        TestName: "Power Button",
        TestStatus: this.SelectedDiagnose.power
      });
      this.testList.push({
        TestID: 44,
        TestName: "Charging Port",
        TestStatus: this.SelectedDiagnose.charging
      });
      this.testList.push({
        TestID: 23,
        TestName: "Proximity Sensor",
        TestStatus: this.SelectedDiagnose.proximity
      });
      this.testList.push({
        TestID: 26,
        TestName: "Home",
        TestStatus: this.SelectedDiagnose.home
      });
      this.testList.push({
        TestID: 40,
        TestName: "Gyroscope",
        TestStatus: this.SelectedDiagnose.gyroscope
      });
      this.testList.push({
        TestID: 28,
        TestName: "Light Sensor",
        TestStatus: this.SelectedDiagnose.lightsensor
      });
      this.testList.push({
        TestID: 58,
        TestName: "Camera",
        TestStatus: this.SelectedDiagnose.camera
      });
      this.testList.push({
        TestID: 57,
        TestName: "Display",
        TestStatus: this.SelectedDiagnose.display
      });
      this.testList.push({
        TestID: 31,
        TestName: "GPS",
        TestStatus: this.SelectedDiagnose.gps
      });
      this.testList.push({
        TestID: 32,
        TestName: "Speaker",
        TestStatus: this.SelectedDiagnose.speaker
      });
      this.testList.push({
        TestID: 33,
        TestName: "MIC",
        TestStatus: this.SelectedDiagnose.mic
      });
      this.testList.push({
        TestID: 24,
        TestName: "Touch Screen",
        TestStatus: this.SelectedDiagnose.touchscreen
      });
      this.testList.push({
        TestID: 22,
        TestName: "Multi Touch",
        TestStatus: this.SelectedDiagnose.MultiTouch
      });
      this.testList.push({
        TestID: 55,
        TestName: "Device Casing",
        TestStatus: this.SelectedDiagnose.devicecasing
      });

      // this.testList.push({ TestID: "", TestName: 'BluetoothActivation', TestStatus: this.SelectedDiagnose.bluetooth });
      // this.testList.push({ TestID: "", TestName: 'WiFiActivation', TestStatus: this.SelectedDiagnose.wifi });
      // this.testList.push({ TestID: "", TestName: 'PhoneVibrator', TestStatus: this.SelectedDiagnose.vibration });
      // this.testList.push({ TestID: "", TestName: 'SIMCardRemoved', TestStatus: this.SelectedDiagnose.simcard });
      // this.testList.push({ TestID: "", TestName: 'SD Card Removed', TestStatus: this.SelectedDiagnose.sdcard });
      // this.testList.push({ TestID: "", TestName: 'Kill Switch Disabled', TestStatus: this.SelectedDiagnose.killswitch });
      // this.testList.push({ TestID: "", TestName: 'RootedOrJailBroken', TestStatus: this.SelectedDiagnose.rooted });
      // this.testList.push({ TestID: "", TestName: 'CallFunction', TestStatus: this.SelectedDiagnose.call });
      // this.testList.push({ TestID: "", TestName: 'EarphoneJack', TestStatus: this.SelectedDiagnose.jack });
      // this.testList.push({ TestID: "", TestName: 'Volume', TestStatus: this.SelectedDiagnose.volume });
      // this.testList.push({ TestID: "", TestName: 'PowerButton', TestStatus: this.SelectedDiagnose.power });
      // this.testList.push({ TestID: "", TestName: 'ChargingPort', TestStatus: this.SelectedDiagnose.charging });
      // this.testList.push({ TestID: "", TestName: 'ProximitySensor', TestStatus: this.SelectedDiagnose.proximity });
      // this.testList.push({ TestID: "", TestName: 'HomeButton', TestStatus: this.SelectedDiagnose.home });
      // this.testList.push({ TestID: "", TestName: 'Gyroscope', TestStatus: this.SelectedDiagnose.gyroscope });
      // this.testList.push({ TestID: "", TestName: 'Light Sensor', TestStatus: this.SelectedDiagnose.lightsensor });
      // this.testList.push({ TestID: "", TestName: 'Camera', TestStatus: this.SelectedDiagnose.camera });
      // this.testList.push({ TestID: "", TestName: 'Display', TestStatus: this.SelectedDiagnose.display });
      // this.testList.push({ TestID: "", TestName: 'GPS', TestStatus: this.SelectedDiagnose.gps });
      // this.testList.push({ TestID: "", TestName: 'Speaker', TestStatus: this.SelectedDiagnose.speaker });
      // this.testList.push({ TestID: "", TestName: 'Mic', TestStatus: this.SelectedDiagnose.mic });
      // this.testList.push({ TestID: "", TestName: 'ScreenTouch', TestStatus: this.SelectedDiagnose.touchscreen });
      // this.testList.push({ TestID: "", TestName: 'MultiTouch', TestStatus: this.SelectedDiagnose.MultiTouch });
      // this.testList.push({ TestID: "", TestName: 'DeviceCasing', TestStatus: this.SelectedDiagnose.devicecasing });
    }
  }

  sendContractEmail(details) {

    this.spinner.show();

    var maildata = {
      UniqueID: details.UniqueID,
      QrCodeID: details.QrCodeID,
      OrderID: details.OrderID,
      StartDate: null,
      EndDate: null,
    };
    this._service.Add(maildata, 'api/complete-order-mail').then(result => {
      if (result === true) {
        // this.contractmail = true;
        this.toster.success(this.translatePipe.transform("An_e_mail_with_contract_detail", 'Contract_Sent'));
      } else {
        console.log('Error:', result);
        this.toster.error(result, 'Error_Occured');
      }
      this.spinner.hide();
    })
      .catch(error => {
        this.spinner.hide();
        console.log(error);
        this.toster.error(error, 'Error_Occured');
      });
  }

  printcontract(PrintContractCopy) {

    sessionStorage.setItem('PrintContractCopy', PrintContractCopy)
    console.log(this.SelectedDiagnose)
    let item = [];
    item[0] = this.SelectedDiagnose.OrderData;
    
    if(this.SelectedDiagnose.OrderData.IdentityType=="2" || this.SelectedDiagnose.OrderData.IdentityType=="3"){
      var IdentityType=appConfig.IdentityTypes.find(x => x.id == this.SelectedDiagnose.OrderData.IdentityType);
      this.SelectedDiagnose.OrderData.IdentityType=IdentityType.value;
      }
    sessionStorage.setItem("WrapperData", JSON.stringify(this.SelectedDiagnose.OrderData));
    this.StoreInfo = JSON.parse(sessionStorage.getItem("StoreInfo"));
    sessionStorage.setItem("StoreInfo", JSON.stringify(this.StoreInfo));
    // this.router.navigate(['/print-contract/', this.SelectedDiagnose.OrderData.OrderDetailID]);
    let url = '/print-contract/' + this.SelectedDiagnose.OrderData.OrderDetailID;
    window.open(url, '_blank');
  }
  printWrapper() {
    debugger
    console.log(this.SelectedDiagnose)
    let item = [];
    var TestName ;
    var GroupTestsList = [];
    item[0] = this.SelectedDiagnose.OrderData;
    var orderData = this.SelectedDiagnose.OrderData;
    orderData['CosmaticCondition'] = appConfig.cosmaticTest.filter(x=> this.SelectedDiagnose.OrderData.FailTestId.split(',')
                                      .includes(x.id.toString()))[0].value;
                                      // #region 14-03-2024
    if(orderData.IsWebManualOrder){
      var _lst=this.SelectedDiagnose.Result.filter(x=> this.SelectedDiagnose.OrderData.FailTestId.split(',').includes(x.GroupID.toString()) && x.TestTypeID != 3);
      
      if(_lst.length == 0)
      {orderData["TestName"] = 'N/A';}
      else{
      for(var i = 0; i < _lst.length; i++)  {
         TestName += _lst[i].GroupName+ ', ';
           orderData["TestName"] = TestName.replace(/undefined/g, '').replace(/,\s*$/, '') ;
      }
    }
    } 
    else{
      //var _lst = JSON.parse(sessionStorage.getItem("MobileTestGroupingList"));
      var _lst=this.SelectedDiagnose.Result.filter(x=> this.SelectedDiagnose.OrderData.FailTestId.split(',').includes(x.GroupID.toString()) && x.TestTypeID != 3);
      if(_lst.length == 0)
      {orderData["TestName"] = 'N/A';}
      else{
      for(var i = 0; i < _lst.length; i++)  {         
        GroupTestsList = _lst[i].GroupTestsList;
        if(GroupTestsList.length > 0){
         for(var j = 0; j < GroupTestsList.length; j++){
          if(GroupTestsList[j].TestStatus == 0 || GroupTestsList[j].TestStatus == -1){
          TestName += GroupTestsList[j].TestName+ ', ';
           orderData["TestName"] = TestName.replace(/undefined/g, '').replace(/,\s*$/, '') ;
          }
        }
        }
    }
  }   
  } 
    // #endregion 14-03-2024
    // this.SelectedDiagnose.Result.filter(x=>x.TestTypeID == appConfig.TestTypeID &&
    //   this.SelectedDiagnose.OrderData.FailTestId.split(',').includes(x.GroupID) )[0].GroupName;
    sessionStorage.setItem("WrapperData", JSON.stringify(orderData));
    this.StoreInfo = JSON.parse(sessionStorage.getItem("StoreInfo"));
    sessionStorage.setItem("StoreInfo", JSON.stringify(this.StoreInfo));
    // this.router.navigate(['/print-order-wrapper/', this.SelectedDiagnose.OrderData.OrderDetailID]);
    let url = '/print-order-wrapper/' + this.SelectedDiagnose.OrderData.OrderDetailID;
    window.open(url, '_blank');
  }

  converttohtml(data) {

    const StoreInfo = JSON.parse(sessionStorage.getItem("StoreInfo"));
    let htmltemplate = this.responseHtml._body;
    htmltemplate = htmltemplate
      .replace("{{WrapperData.FirstName}}", data.FirstName)
      .replace("{{WrapperData.LastName}}", data.LastName)
      .replace("{{WrapperData.Email}}", data.Email)
      .replace("{{WrapperData.PhoneNo}}", data.PhoneNo)
      .replace("{{WrapperData.IdentityType}}", data.IdentityType)
      .replace("{{WrapperData.IdentityNumber}}", data.IdentityNumber)
      .replace("{{WrapperData.make}}", data.make)
      .replace("{{WrapperData.model}}", data.model)
      .replace("{{WrapperData.capacity}}", data.capacity)
      .replace("{{WrapperData.UniqueID}}", data.UniqueID)
      .replace("{{WrapperData.IMEI1}}", data.IMEI1)
      .replace("{{WrapperData.IMEI2}}", data.IMEI2)
      .replace("{{WrapperData.DevicePrice}}", data.DevicePrice)
      .replace("{{WrapperData.DeductionPercentage}}", data.DeductionPercentage)
      .replace("{{WrapperData.DeductionGroup}}", data.DeductionGroup)
      .replace("{{WrapperData.OfferPrice}}", data.OfferPrice)
      .replace("{{StoreInfo.StoreID}}", StoreInfo.StoreID)
      .replace("{{StoreInfo.Store}}", StoreInfo.Store)
      .replace("{{StoreInfo.EnterprisePartner}}", StoreInfo.EnterprisePartner)
      .replace("{{WrapperData.OrderID}}", data.OrderID)
      .replace("{{StoreInfo.StoreID}}", StoreInfo.StoreID)
      .replace("{{StoreInfo.Store}}", StoreInfo.Store);

    return htmltemplate;
  }
  ignoreclick() {
    return false;
  }

  filtertest(testlist) {
    let ids = appConfig.ParentTestIDNotShownInAutomateTest;
    const items = testlist.filter(x => x.TestStatus !== -2 && x.TestID !== 78 && x.TestID !== 79 && !ids.includes(x.TestID));
    return items;
  }
  testStatusChanged(data) {

    console.log(this.TestListWithGroupDeduction)
    this.TestListWithGroupDeduction.Result.forEach(element => {
      element.GroupTestsList.forEach(x => {
        if (x.TestID == data.TestID) {
          if (x.TestStatus == 0 || x.TestStatus == -1) {
            x.TestStatus = 1
          }
          else {
            x.TestStatus = 0
          }
        }
      });
    });

  }

  CheckTestResult(data, GroupName): string {
    var className = ''
    const IsData = data.GroupTestsList.find(x => x.TestName === GroupName)
    if (this.SelectedDiagnose.OrderData.PassTestId) {
      this.SelectedDiagnose.OrderData.PassTestId.split(',').forEach(element => {
        if (Number(element) === Number(data.GroupID) && data.GroupTestsList.length === 0 || (data.IsParentPass && data.GroupTestsList.length === 1)) {
          className = 'green'
        }
      });
    }

    if (this.SelectedDiagnose.OrderData.FailTestId) {
      this.SelectedDiagnose.OrderData.FailTestId.split(',').forEach(element => {
        if (Number(element) === Number(data.GroupID) && data.GroupTestsList.length === 0 || (!data.IsParentPass && data.GroupTestsList.length === 1)) {
          className = 'red'
        }
      });
    }

    return className
  }
  CheckIfTextExistOrNot(data): boolean {
    let isExist = false;
    if (this.SelectedDiagnose.OrderData.PassTestId && data.TestTypeID != 3) {
      this.SelectedDiagnose.OrderData.PassTestId.split(',').forEach(element => {
        if (Number(element) === Number(data.GroupID)) {
          isExist = true
        }
      });
    }

    if (this.SelectedDiagnose.OrderData.FailTestId) {
      this.SelectedDiagnose.OrderData.FailTestId.split(',').forEach(element => {
        if (Number(element) === Number(data.GroupID)) {
          isExist = true
        }
      });
    }
    return isExist
    // SelectedDiagnose.OrderData.PassTestId?.split(',').includes(item.GroupID.toString())
    // || SelectedDiagnose.OrderData.FailTestId?.split(',').includes(item.GroupID.toString())
  }
  ViewImages() {
    this.spinner.show();

    // let item={
    //   "OrderDetailID":9105
    // }
    let OrderDetailID = this.SelectedDiagnose.OrderData.ID.toString();
    this._service.getMethod('api/get-customer-encrypted-doc/' + OrderDetailID).then(result => {

      console.log(result)
      this.galleries = result.Data[0].DocumentData.split("/");
      this.galleries.forEach(element => {
        //result.Data.DocumentData.push(element)
        result.Data.push({ DocumentData: element })
      })

      // sessionStorage.setItem("Galleries",JSON.stringify(this.galleries));
      if (result.IsSuccess) {

        result.OrderDetailID = this.SelectedDiagnose.OrderData.OrderDetailID;
        const modalRef = this.modalService.open(ImageViewerComponent, { size: 'lg' });
        // result=result.Data.shift();
        modalRef.componentInstance.Images = result
        this.spinner.hide();
      } else {
        this.toster.warning(this.translatePipe.transform('No_records_found'))
        this.spinner.hide();
      }
    },
      err => {
        console.log(err)
        this.spinner.hide();
      })
  }
  getDeviceConditionsImage(details) {

    const reBody = {
      //UDI:'test123' //'BE1ED90E82974D3',
      UDI: details.UDI,
    };
    this._service.Add(reBody, 'api/get-capturing-condition-images').then(result => {

      if (result && result.IsSuccess && result.Data.ImagesList && result.Data.ImagesList.length > 0) {
        //this.SelectedDiagnose.DeviceConditionImages = result.Data.ImagesList;
        let body = [];
        result.Data.ImagesList.forEach(element => {
          let Data = {
            'DocumentData': element.ImageUrl
          }
          body.push(Data)

        });
        let _response = {
          "Data": body,
          "OrderDetailID": this.SelectedDiagnose.OrderData.OrderDetailID
        }


        const modalRef = this.modalService.open(ImageViewerComponent, { size: 'lg' });
        modalRef.componentInstance.Images = _response//result.Data.ImagesList

      } else {
        this.toster.warning(this.translatePipe.transform('No_record_found'))
      }
    })
      .catch(error => {
        this.spinner.hide();
        console.log(error);
      });
  }
  cancelOrder(Comments) {
    debugger
    if(Comments == undefined)
    {
      Comments = ' ';
    }
    this.StoreInfo = JSON.parse(sessionStorage.getItem('StoreInfo'));
    var reBody = {
      OrderDetailID: this.SelectedDiagnose.OrderData.OrderDetailID,
      Comment:Comments,
      UserID:this.StoreInfo.UserID 


    }
    this._service.Add(reBody, 'api/CancelOrder').then(result => {
      if (result && result.length > 0) {
        if (result[0].IsSuccess) {
          this.toster.success(this.translatePipe.transform('Order_canceled_successfully'));
          this.RefreshStoreList.emit(true);
          this.fnClosePopUp();
        }
      } else {
        this.toster.warning(this.translatePipe.transform('Something went wrong'))
      }
    })
      .catch(error => {
        this.spinner.hide();
        console.log(error);
      });
  }
  opensignatureModal(item) {
    debugger
    this.img = null;
    let storeInfo = JSON.parse(sessionStorage.getItem('StoreInfo'));
    var ExpiryTime = new Date();
    ExpiryTime.setMinutes(ExpiryTime.getMinutes() + appConfig.ContractPageExpiryTime);
    var language = sessionStorage.getItem('language');
    if(item.IdentityType=="2" || item.IdentityType=="3"){
      // check incase identity type is already assigned
      var IdentityType=appConfig.IdentityTypes.find(x => x.id == item.IdentityType);
      item.IdentityType=IdentityType.value;
    }
    let obj = {
      FirstName: item.FirstName,
      CustomerLastName: item.LastName,
      CompanyName: item.CompanyName,
      IdentityType: item.IdentityType,
      TempOrderID: item.ID,
      Make: item.Make,
      Model: item.Model,
      Capacity: item.Capacity,
      SerialNumber: item.SerialNumber,
      IMEI: item.IMEI,
      Street: item.Street,
      BuildingNo: item.BuildingNo,
      ApartmentNo: item.ApartmentNo,
      PostCode: item.PostCode,
      IdentificationNo: item.IdentificationNo,
      IDNo: item.IDNo,
      PESEL: item.PESEL,
      City: item.City,
      QuotedPrice: item.QuotedPrice,
      ContactName : item.ContactName,
      EnterprisePartner: storeInfo.EnterprisePartner,
      EnterprisePartnerID: storeInfo.EnterprisePartnerID,
      CurrencySymbol: storeInfo.CurrencySymbol,
      Store: storeInfo.Store,
      StoreID: storeInfo.StoreID,
      OrderDetailID: this.SelectedDiagnose.OrderData.OrderDetailID,
      ExpiryTime: ExpiryTime,
      Name: storeInfo.Name,
      LastName: storeInfo.LastName,
      StoreCity: storeInfo.City,
      LanguageCode: language,
      IsCommercialSeller: item.IsCommercialSeller,
      NIP: item.NIP,
      OrderCreatedOn: item.OrderCreatedOn
    }
    //=JSON.stringify(obj);
    let strg = CryptoJS.AES.encrypt(JSON.stringify(obj), '123').toString();
    let currentUrl = location.origin;
    currentUrl = currentUrl + '/print-contract/'
    this.DigitalSigQrcode = currentUrl + 'data?data=' + strg;
    console.log('Qrcode Url:- ' + this.DigitalSigQrcode);
    const modalRef = this.modalService.open(ESignatureComponent, { size: 'lg' });
    modalRef.componentInstance.DigitalSigQrcode = this.DigitalSigQrcode;
    modalRef.componentInstance.OrderData = this.SelectedDiagnose.OrderData;
    modalRef.componentInstance.emitService.subscribe((value) => {
      // this.IsContractSigned = value;
      this.SelectedDiagnose.OrderData.IsContractSigned = value;
    });
    this.ModalStyle = {
      display: 'block',
      'z-index': 9999
    }
  }
  printSigncontract() {
    debugger
    sessionStorage.setItem('fileName', appConfig.SignContractPath + 'SaleAgreement_' + this.SelectedDiagnose.OrderData.OrderDetailID + '.pdf');
    window.open('print-file', '_blank');
    // let url = appConfig.SignContractPath + 'SaleAgreement_' + this.SelectedDiagnose.OrderData.OrderDetailID + '.pdf';
    // console.log(url);
    // this.router.navigate([]).then(result => {
    //   window.open(`${url}`,
    //     '_blank');
    // });
  }
  OrderCancel()
  {

this.isCancelModal = true;
 this.Comments='';

  }
PrintVoucher() {
   debugger

    sessionStorage.setItem('fileName', this.VoucherPdf + this.SelectedDiagnose.OrderData.OrderDetailID + '.pdf');
    window.open('print-file', '_blank');

  }
}

